import React from 'react';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import GridLayout from './features/layout/GridLayout';

const App = () => {
    return <GridLayout />;
};

export default App;
