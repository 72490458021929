import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import { ERROR, LOADING } from '../app/statusTypes';

const getStatusMessage = (status) => {
    switch (status) {
        case ERROR:
            return 'Error';
        case LOADING:
            return 'Cargando...';
        default:
            return status;
    }
};

const Loader = ({ status, errorMessage }) => (
    <div className="loader-spinner d-flex justify-content-center align-items-center h-100">
        {status === ERROR ? (
            <div className="alert alert-light">
                <i className="fa-solid fa-triangle-exclamation me-1"></i>
                {errorMessage
                    ? `${getStatusMessage(status)}: ${errorMessage}`
                    : getStatusMessage(status)}
            </div>
        ) : (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">{getStatusMessage(status)}</span>
            </Spinner>
        )}
    </div>
);

export default Loader;
