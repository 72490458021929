import React from 'react';
import { Table as BootstrapTable } from 'react-bootstrap';

import formatValue from '../utils/formatValue';

const Table = ({ data: datasets }) => {
    const [data] = datasets;
    const { columns, rows } = data;

    return (
        <BootstrapTable>
            <thead>
                <tr>
                    {columns.map((column) => (
                        <th key={column.label}>{column.label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, index) => (
                    <tr key={`tr-${index}`}>
                        {columns.map((column) => (
                            <td key={`td-${index}-${column.field}`}>
                                {formatValue(row[column.field], column.format)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </BootstrapTable>
    );
};

export default Table;
