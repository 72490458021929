import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './Dates.css';

import { selectStartDate, selectEndDate, updateFilters } from './layoutSlice';

registerLocale('es', es);

const Dates = () => {
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const dispatch = useDispatch();

    return (
        <div className="pb-3 dates-container">
            <i className="fa-regular fa-calendar"></i>
            <span className="px-2">Desde:</span>
            <DatePicker
                selected={moment(startDate).toDate()}
                onChange={(date) => dispatch(updateFilters({ startDate: moment(date).valueOf() }))}
                locale="es"
            />
            <span className="px-2">Hasta:</span>
            <DatePicker
                selected={moment(endDate).toDate()}
                onChange={(date) => dispatch(updateFilters({ endDate: moment(date).valueOf() }))}
                locale="es"
            />
        </div>
    );
};

export default Dates;
