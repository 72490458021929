import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';

import './GridLayout.css';
import Dates from './Dates';

import useBreakpoint from '../../hooks/useBreakpoint';
import Card from '../../components/Card';
import DoughnutChart from '../../components/DoughnutChart';
import LineChart from '../../components/LineChart';
import BarChart from '../../components/BarChart';
import PieChart from '../../components/PieChart';
import Table from '../../components/Table';
import Panel from '../../components/Panel';
import withDatasource from '../panels/withDatasource';

import {
    getLayout,
    selectLayout,
    selectStatus,
    selectStartDate,
    selectEndDate,
} from './layoutSlice';
import { getPanel, selectPanelDatasetById, selectPanelStatusById } from '../panels/panelsSlice';

const MAX_NUMBER_OF_COLUMNS_BY_ROW = 12;

const keysToComponentMap = {
    card: Card,
    'pie-chart': PieChart,
    'donut-chart': DoughnutChart,
    'line-chart': LineChart,
    'bar-chart': BarChart,
    table: Table,
};

const getComponent = (type) => {
    return keysToComponentMap[type];
};

function getGridPosition(index, layout, breakpoint) {
    const activateLayout = layout[breakpoint];
    if (Array.isArray(activateLayout) && activateLayout?.[index]) {
        return activateLayout[index].gridPos;
    }

    switch (activateLayout) {
        case 'stack':
        default:
            return {
                y: index,
                x: 0,
                w: MAX_NUMBER_OF_COLUMNS_BY_ROW,
                h: 1,
            };
    }
}

function calculateGridArea(gridPos) {
    const position = {
        rowStart: gridPos.y + 1,
        colStart: gridPos.x + 1,
        rowEnd: gridPos.y + gridPos.h + 1,
        colEnd: gridPos.x + gridPos.w + 1,
    };
    return `${position.rowStart} / ${position.colStart} / ${position.rowEnd} / ${position.colEnd}`;
}

const GridLayout = ({ data }) => {
    const { panels, gridLayout } = data;
    const availableBreakpoints = Object.keys(gridLayout);

    const activeBreakpoint = useBreakpoint(availableBreakpoints);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    return (
        <Container fluid className="p-4 dashboard-grid-container">
            <Dates />
            <div className="dashboard-grid">
                {panels?.map((panel, index) => {
                    const { id, title, titleIcon, titleCls, datasource } = panel;
                    const component = getComponent(panel.component);

                    const ComponentWithDatasource = component
                        ? withDatasource(
                              component,
                              () => getPanel({ id, datasource, startDate, endDate }),
                              (state) => selectPanelDatasetById(state, id),
                              (state) => selectPanelStatusById(state, id),
                          )
                        : () => <div></div>;
                    const gridPos = getGridPosition(index, gridLayout, activeBreakpoint);
                    const panelPosition = calculateGridArea(gridPos);

                    return (
                        <Panel
                            key={`panel-${id}`}
                            title={title}
                            titleIcon={titleIcon}
                            titleCls={titleCls}
                            style={{
                                gridArea: panelPosition,
                            }}
                        >
                            <ComponentWithDatasource id={id} />
                        </Panel>
                    );
                })}
            </div>
        </Container>
    );
};

export default withDatasource(GridLayout, getLayout, selectLayout, selectStatus);
