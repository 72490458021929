import { getLayout } from '../features/layout/layoutSlice';

const TIMEOUT_IFRAME_PARENT_RESPONSE = 10000; // 10 seconds
let configuration;

function setupFromIframe(store) {
    console.log('Using configuration from iframe');
    const allowedDomains = [window.location.ancestorOrigins?.[0]];
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(
            () => reject('Sin respuesta de Safite Frontend'),
            TIMEOUT_IFRAME_PARENT_RESPONSE,
        );

        const paramsString = window.location.search;
        const searchParams = new URLSearchParams(paramsString);
        const idParam = searchParams.get('id');

        window.addEventListener(
            'message',
            (event) => {
                if (!allowedDomains.includes(event.origin)) {
                    return;
                }

                switch (event.data?.command) {
                    case 'setupResponse': {
                        const { apiUrl, token, id } = event.data;
                        if (id !== idParam) {
                            return;
                        }

                        clearTimeout(timeoutId);
                        resolve({ apiUrl, token });
                        break;
                    }
                    case 'refreshDashboard': {
                        const { id } = event.data;
                        if (id !== idParam) {
                            return;
                        }

                        store.dispatch(getLayout());
                        break;
                    }
                    default:
                        break;
                }
            },
            false,
        );

        console.log('Requesting dashboard setup', idParam);

        window.parent.postMessage({ command: 'setup', id: idParam }, '*');
    });
}

function setupFromEnvironmentVariables() {
    console.log('Using configuration from environment variables');
    return {
        apiUrl: process.env.REACT_APP_API_URL,
        token: process.env.REACT_APP_TOKEN,
    };
}

function setupFromLocal() {
    return {
        apiUrl: '/response/dashboard.json',
        token: null,
    };
}

export function initialize(store) {
    const isIframe = window.location !== window.parent.location;
    const isEnvinronmentVariables = !!process.env.REACT_APP_API_URL;

    if (isIframe) {
        configuration = setupFromIframe(store);
    } else if (isEnvinronmentVariables) {
        configuration = setupFromEnvironmentVariables();
    } else if (process.env.NODE_ENV === 'development') {
        configuration = setupFromLocal();
    } else {
        throw new Error('No configuration found');
    }
}

export async function getApiUrl() {
    const { apiUrl } = await configuration;
    return apiUrl;
}

export async function getToken() {
    const { token } = await configuration;
    return token;
}
