import { useEffect, useState } from 'react';

const keysToBreakpointMap = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

function useBreakpoint(breakpoints) {
    const [activeBreakpoint, setActiveBreakpoint] = useState('xs');
    useEffect(() => {
        function updateActiveBreakpoint() {
            const mostRelevantActiveBreakpoint = availableBreakpoints.reduce((acc, curr) =>
                curr.active && curr.value > acc.value ? curr : acc,
            );

            setActiveBreakpoint(mostRelevantActiveBreakpoint.name);
        }

        function handleChange(breakpointName, e) {
            const foundBreakpoint = availableBreakpoints.find(
                (breakpoint) => breakpoint.name === breakpointName,
            );

            foundBreakpoint.active = e.matches;

            updateActiveBreakpoint();
        }

        const availableBreakpoints = breakpoints.map((breakpoint) => {
            const breakpointValue = keysToBreakpointMap[breakpoint];
            const matchQueryList = window.matchMedia(`(min-width: ${breakpointValue}px)`);
            matchQueryList.addEventListener('change', handleChange.bind(null, breakpoint));
            return {
                name: breakpoint,
                value: breakpointValue,
                active: matchQueryList.matches,
                matchQueryList,
            };
        });

        updateActiveBreakpoint();

        return () => {
            availableBreakpoints.forEach((breakpoint) => {
                breakpoint.matchQueryList.removeEventListener('change', handleChange);
            });
        };
    }, [activeBreakpoint, breakpoints]);

    return activeBreakpoint;
}

export default useBreakpoint;
