import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { updateFilters } from './../commonActions';
import { IDLE, LOADING, SUCCEEDED, ERROR } from '../../app/statusTypes';
import { fetchDashboard } from '../../api/safite';

const initialState = {
    value: null,
    status: {
        value: IDLE,
        errorMessage: null,
    },
    filters: {
        startDate: moment().subtract(1, 'month').valueOf(),
        endDate: moment().valueOf(),
    },
};

export const getLayout = createAsyncThunk('layout/getLayout', async (_, { rejectWithValue }) => {
    try {
        const response = await fetchDashboard();
        return response;
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLayout.pending, (state) => {
                state.status.value = LOADING;
            })
            .addCase(getLayout.fulfilled, (state, action) => {
                const isFailed = action.payload?.data?.success ?? false;
                state.status.value = isFailed ? ERROR : SUCCEEDED;
                state.value = action.payload || [];
            })
            .addCase(getLayout.rejected, (state, action) => {
                state.status.value = ERROR;
                state.status.errorMessage = action?.payload?.message || 'Error desconocido';
            })
            .addCase(updateFilters, (state, action) => {
                state.filters = { ...state.filters, ...action.payload };
                const startDate = state.filters.startDate;
                const endDate = state.filters.endDate;
                const currentTime = moment();

                if (moment(endDate).diff(currentTime) > 0) {
                    state.filters.endDate = currentTime;
                }

                if (moment(endDate).diff(startDate) <= 0) {
                    state.filters.startDate = moment(endDate).subtract(1, 'month').valueOf();
                }
            });
    },
});

// Actions
export { updateFilters };

// Selects
export const selectStartDate = (state) => state.layout.filters.startDate;
export const selectEndDate = (state) => state.layout.filters.endDate;
export const selectLayout = (state) => state.layout.value;
export const selectStatus = (state) => state.layout.status;

export default layoutSlice.reducer;
