import React from 'react';
import { Table } from 'react-bootstrap';

import formatValue from '../utils/formatValue';

const Card = ({ data }) => {
    const rows = data[0].columns.map(({ label, field, format }) => ({
        name: label,
        value: formatValue(data[0].rows[0][field], format),
    }));

    return (
        <Table>
            <tbody>
                {rows.map((row) => (
                    <tr key={row.name}>
                        <td>{row.name}</td>
                        <td className="text-end">{row.value}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default Card;
