import { configureStore } from '@reduxjs/toolkit';

import layoutSlice from '../features/layout/layoutSlice';
import panelsSlice from '../features/panels/panelsSlice';

export const store = configureStore({
    reducer: {
        panels: panelsSlice,
        layout: layoutSlice,
    },
});
