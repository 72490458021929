import React from 'react';
import { Bar, Line, Doughnut, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

import defaultColors from '../app/defaultChartColors';

ChartJS.register(...registerables);

const Chart = ({ type, data: datasets }) => {
    const labelColumn = datasets[0]?.columns[0].field;
    const valueColumn = datasets[0]?.columns[1].field;
    const colorColumn = datasets[0]?.columns[2]?.field;

    const formattedData = {
        labels: datasets[0]?.rows.map((row) => row[labelColumn]),
        datasets: datasets.map(({ label, rows, color }, index) => ({
            label,
            data: rows.map((row) => row[valueColumn]),
            backgroundColor: rows.map((row) => row[colorColumn] || color || defaultColors[index]),
            borderColor: color || defaultColors[index],
        })),
    };

    switch (type) {
        case 'line':
            return <Line data={formattedData} />;
        case 'bar':
            return <Bar data={formattedData} />;
        case 'doughnut':
            return <Doughnut data={formattedData} />;
        case 'pie':
            return <Pie data={formattedData} />;
        default:
            return null;
    }
};

export default Chart;
