import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';

const Panel = ({ title, titleIcon, titleCls, className, style, children }) => {
    const iconCls = titleIcon ? `fa fa-${titleIcon} me-2` : '';
    return (
        <BootstrapCard className={`shadow ${className || ''}`} style={style}>
            <BootstrapCard.Body>
                <BootstrapCard.Title className={titleCls}>
                    {iconCls ? <i className={iconCls}></i> : null}
                    {title}
                </BootstrapCard.Title>
                {children}
            </BootstrapCard.Body>
        </BootstrapCard>
    );
};

export default Panel;
