import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IDLE, OUTDATED, SUCCEEDED } from '../../app/statusTypes';
import Loader from '../../components/Loader';

const withDatasource = (WrappedComponent, getData, selectData, selectStatus) => {
    return (props) => {
        const data = useSelector(selectData);
        const status = useSelector(selectStatus);
        const dispatch = useDispatch();

        useEffect(() => {
            if (!status || [IDLE, OUTDATED].includes(status?.value)) {
                dispatch(getData());
            }
        }, [dispatch, status]);

        return status?.value === SUCCEEDED ? (
            <WrappedComponent data={data} {...props} />
        ) : (
            <Loader status={status?.value} errorMessage={status?.errorMessage} />
        );
    };
};

export default withDatasource;
