const regex = /\((\d*)\)/;

function addDecimalPointDigits(value, format) {
    const decimalPointDigits = format.match(regex)?.[1];
    return decimalPointDigits ? Number(value).toFixed(decimalPointDigits) : value;
}

export default function formatValue(value, format) {
    if (typeof value === 'undefined') {
        return value;
    }

    switch (format) {
        case format.match(/percentage/)?.input:
            return `${addDecimalPointDigits(value, format)}%`;
        case format.match(/number/)?.input:
            return addDecimalPointDigits(value, format);
        case format.match(/money/)?.input:
            const decimalPointDigits = format.match(regex)?.[1];
            try {
                return Number(value).toLocaleString('es-CO', {
                    style: 'currency',
                    currency: 'COP',
                    minimumFractionDigits: decimalPointDigits || 2,
                });
            } catch (e) {
                console.log('Error', e);
                return value;
            }
        case 'string':
        default:
            return value;
    }
}
