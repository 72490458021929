import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { updateFilters } from './../commonActions';
import { LOADING, SUCCEEDED, ERROR, OUTDATED } from '../../app/statusTypes';
import { fetchPanel } from '../../api/safite';

const panelsAdapter = createEntityAdapter();

export const getPanel = createAsyncThunk(
    'panels/getPanel',
    async ({ id, datasource, startDate, endDate }, { rejectWithValue }) => {
        try {
            const { url } = datasource;
            const response = await fetchPanel(url, { startDate, endDate });
            return { id, data: response };
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const panelsSlice = createSlice({
    name: 'panels',
    initialState: panelsAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPanel.pending, (state, action) => {
                const panelId = action.meta.arg.id;
                panelsAdapter.addOne(state, {
                    id: panelId,
                    status: {
                        value: LOADING,
                        errorMessage: null,
                    },
                });
            })
            .addCase(getPanel.fulfilled, (state, action) => {
                panelsAdapter.upsertOne(state, {
                    ...action.payload,
                    status: {
                        value: SUCCEEDED,
                        errorMessage: null,
                    },
                });
            })
            .addCase(getPanel.rejected, (state, action) => {
                const panelId = action.meta.arg.id;
                panelsAdapter.updateOne(state, {
                    id: panelId,
                    status: {
                        value: ERROR,
                        errorMessage: action?.payload?.message || 'Error desconocido',
                    },
                });
            })
            .addCase(updateFilters, (state, action) => {
                const ids = panelsAdapter.getSelectors().selectIds(state);
                const idsToUpdate = ids.map((id) => ({
                    id,
                    changes: {
                        status: {
                            value: OUTDATED,
                            errorMessage: null,
                        },
                    },
                }));
                panelsAdapter.updateMany(state, idsToUpdate);
            });
    },
});

// Actions

// Selects
const { selectAll: selectPanels, selectById: selectPanelById } = panelsAdapter.getSelectors(
    (state) => state.panels,
);

export { selectPanels, selectPanelById };
export const selectStatus = (state) => state.panels.status;
export const selectPanelStatusById = (state, id) => selectPanelById(state, id)?.status;
export const selectPanelDatasetById = (state, id) => selectPanelById(state, id)?.data?.datasets;

export default panelsSlice.reducer;
