import { getApiUrl, getToken } from '../app/setup';

export async function fetchDashboard() {
    const apiUrl = await getApiUrl();
    const response = await fetch(apiUrl, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });

    if (response.status === 200) {
        const jsonResponse = await response.json();
        return jsonResponse;
    } else {
        const error = await response.json();
        throw error;
    }
}

export async function fetchPanel(url, { startDate, endDate }) {
    const parsedUrl = new URL(url);

    parsedUrl.searchParams.append('startDate', startDate);
    parsedUrl.searchParams.append('endDate', endDate);

    const newUrl = parsedUrl.toString();

    const response = await fetch(newUrl.toString(), {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });

    if (response.status === 200) {
        const jsonResponse = await response.json();
        return jsonResponse;
    } else {
        const error = await response.json();
        throw error;
    }
}
